import img1_2014 from '../assets/Compressed_Images/years/2014_1.jpg'
import img2_2014 from '../assets/Compressed_Images/years/2014_2(1).jpg'
import img3_2014 from '../assets/Compressed_Images/years/2014_3(1).jpg'
import img1_2015 from '../assets/Compressed_Images/years/2015_1.jpg'
import img2_2015 from '../assets/Compressed_Images/years/2015_2.jpg'
import img3_2015 from '../assets/Compressed_Images/years/2015_3.jpg'
import img1_2016 from '../assets/Compressed_Images/years/2016_1.jpg'
import img2_2016 from '../assets/Compressed_Images/years/2016_2.jpg'
import img3_2016 from '../assets/Compressed_Images/years/2016_3.jpg'
import img1_2017 from '../assets/Compressed_Images/years/2017_1(1).jpg'
import img2_2017 from '../assets/Compressed_Images/years/2017_2.jpg'
import img3_2017 from '../assets/Compressed_Images/years/2017_3.jpg'
import img1_2018 from '../assets/Compressed_Images/years/2018_1.jpg'
import img2_2018 from '../assets/Compressed_Images/years/2018_2(1).jpg'
import img3_2018 from '../assets/Compressed_Images/years/2018_3(1).jpg'
import img1_2019 from '../assets/Compressed_Images/years/2019_1.jpg'
import img2_2019 from '../assets/Compressed_Images/years/2019_2.jpg'
import img3_2019 from '../assets/Compressed_Images/years/2019_3.jpg'
import img1_2020 from '../assets/Compressed_Images/years/2020_1.jpg'
import img2_2020 from '../assets/Compressed_Images/years/2020_2.jpg'
import img3_2020 from '../assets/Compressed_Images/years/2020_3(1).jpg'
import img1_2021 from '../assets/Compressed_Images/years/2021_1(1).jpg'
import img2_2021 from '../assets/Compressed_Images/years/2021_2(1).jpg'
import img3_2021 from '../assets/Compressed_Images/years/2021_3.jpg'
import img1_2022 from '../assets/Compressed_Images/years/2022_1(1).jpg'
import img2_2022 from '../assets/Compressed_Images/years/2022_2(1).jpg'
import img3_2022 from '../assets/Compressed_Images/years/2022_3(1).jpg'
import img1_2023 from '../assets/Compressed_Images/years/2023_1.JPG'
import img2_2023 from '../assets/Compressed_Images/years/2023_2.jpeg'
import img3_2023 from '../assets/Compressed_Images/years/2023_3.jpeg'
import img1_2024 from '../assets/Compressed_Images/years/2024_1.jpeg'
import img2_2024 from '../assets/Compressed_Images/years/2024_2.jpeg'
import img3_2024 from '../assets/Compressed_Images/years/2024_3.jpg'


export default function Gallery() {
    return <div>
        <div className="d-flex justify-content-center">
            <div className="">
                <h1 className="display-5">2014</h1>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className=" image-container">
                <img src={img1_2014} alt="us1"></img>
            </div>
            <div className=" image-container">
                <img src={img2_2014} alt="us2"></img>
            </div>
            <div className=" image-container">
                <img src={img3_2014} alt="us3"></img>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className="">
                <h1 className="display-5">2015</h1>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className=" image-container">
                <img src={img1_2015} alt="us1"></img>
            </div>
            <div className=" image-container">
                <img src={img2_2015} alt="us2"></img>
            </div>
            <div className=" image-container old-photo">
                <img src={img3_2015} alt="us3"></img>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className="">
                <h1 className="display-5">2016</h1>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className=" image-container">
                <img src={img1_2016} alt="us1"></img>
            </div>
            <div className=" image-container">
                <img src={img2_2016} alt="us2"></img>
            </div>
            <div className=" image-container">
                <img src={img3_2016} alt="us3"></img>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className="">
                <h1 className="display-5">2017</h1>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className=" image-container old-photo2">
                <img src={img1_2017} alt="us1"></img>
            </div>
            <div className=" image-container">
                <img src={img2_2017} alt="us2"></img>
            </div>
            <div className=" image-container">
                <img src={img3_2017} alt="us3"></img>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className="">
                <h1 className="display-5">2018</h1>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className=" image-container">
                <img src={img1_2018} alt="us3"></img>
            </div>
            <div className=" image-container">
                <img src={img2_2018} alt="us1"></img>
            </div>
            <div className=" image-container">
                <img src={img3_2018} alt="us3"></img>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className="">
                <h1 className="display-5">2019</h1>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className=" image-container">
                <img src={img1_2019} alt="us3"></img>
            </div>
            <div className=" image-container">
                <img src={img2_2019} alt="us1"></img>
            </div>
            <div className=" image-container">
                <img src={img3_2019} alt="us3"></img>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className="">
                <h1 className="display-5">2020</h1>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className=" image-container">
                <img src={img1_2020} alt="us3"></img>
            </div>
            <div className=" image-container">
                <img src={img2_2020} alt="us1"></img>
            </div>
            <div className=" image-container">
                <img src={img3_2020} alt="us3"></img>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className="">
                <h1 className="display-5">2021</h1>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className=" image-container">
                <img src={img1_2021} alt="us3"></img>
            </div>
            <div className=" image-container">
                <img src={img2_2021} alt="us1"></img>
            </div>
            <div className=" image-container">
                <img src={img3_2021} alt="us3"></img>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className="">
                <h1 className="display-5">2022</h1>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className=" image-container">
                <img src={img1_2022} alt="us3"></img>
            </div>
            <div className=" image-container">
                <img src={img2_2022} alt="us1"></img>
            </div>
            <div className=" image-container">
                <img src={img3_2022} alt="us3"></img>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className="">
                <h1 className="display-5">2023</h1>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className=" image-container beach-yara-photo">
                <img src={img1_2023} alt="us3"></img>
            </div>
            <div className=" image-container">
                <img src={img2_2023} alt="us1"></img>
            </div>
            <div className=" image-container">
                <img src={img3_2023} alt="us3"></img>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className="">
                <h1 className="display-5">2024</h1>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className=" image-container">
                <img src={img1_2024} alt="us3"></img>
            </div>
            <div className=" image-container">
                <img src={img2_2024} alt="us1"></img>
            </div>
            <div className=" image-container">
                <img src={img3_2024} alt="us3"></img>
            </div>
        </div>
    </div>
}