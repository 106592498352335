import sm_inn from '../assets/sm-inn.jpg'

export default function Venue() {
    return <div>
        <div className="d-flex justify-content-center align-items-center venue">
            <div className="large-image-container">
                <h1 className="display-6">The South Mountain Inn</h1>
                <p>
                    143 North Galen Hall Road <br/>
                    Wernersville, Pennsylvania 19565 
                </p>
                <a target="_blank" href="https://thesouthmountaininn.com/"><img src={sm_inn} alt="inn"></img></a>
            </div>
        </div>
        <div className="accommodations">
            <h2>Guest Accommodations</h2>
            <h2 className="display-6">Hampton Inn Wyomissing</h2>
            <p>
                1800 Papermill Rd. <br/>
                Wyomissing, Pennsylvania 19610
            </p>
            <p>Use this <a href="https://www.hilton.com/en/book/reservation/deeplink/?ctyhocn=RDGWMHX&groupCode=CHH90I&arrivaldate=2024-10-18&departuredate=2024-10-20&cid=OM,WW,HILTONLINK,EN,DirectLink&fromId=HILTONLINKDIRECT">link</a> to book online with our special room rates, or call the hotel directly at 610-374-8100 and reference Peace Foulke Wedding.</p>
        </div>
    </div>
}