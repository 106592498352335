import { Link, useMatch, useResolvedPath } from "react-router-dom"
import "../nav-style.css";

export default function WeddingPartyResponsibilities() {
    // Functions 
    function CustomLink({ to, children, ...props }) {
        const resolvedPath = useResolvedPath(to)
        const isActive = useMatch({path: resolvedPath.pathname, end:true})
        return (
            <li className={isActive ? "active" : ""}>
                <Link to={to}>{children}</Link>
            </li>
        )
    }

    // HTML
    return <div>
        <div className='d-flex justify-content-center align-items-center flex-column mb-3'>
            <nav className="d-flex flex-row align-items-center w-100 nav cheat">
                <ul className="w-100 d-flex justify-content-center h6">
                    <CustomLink to="/vip"> &lt; Back to Cheat Sheets</CustomLink>
                </ul>
            </nav>
        </div>
        <div className='d-flex justify-content-center align-items-center flex-column mb-0 ml-2 mr-2'>
            <div className="m-3">
                <p>We picked you all to be a bigger part of our wedding because we want you to stay bigger parts of our lives. As we’ve said before, our vision for this weekend is to have intentional, wholesome, and memorable moments, and we hope many of those moments are shared with you. Here are a couple things we ask you to keep in mind:</p>
            </div>
            <div className="m-3">
                <h3>Getting Ready</h3>
                <p><span>&#8226;</span> We’ve built a lot of down time into the morning. Feel free to enjoy the inn amenities and spend time with other members of the wedding party. We only need you in specific places for hair/makeup or photos before the ceremony.</p>
                <p><span>&#8226;</span> There is no assigned dress code for getting ready. Girls, please bring something you can slip out of easily if you’re having hair and makeup done.</p>
                <p><span>&#8226;</span> Our photographer won’t be here the whole day but we’d love to have as much documentation as possible of this time together. And actually this goes for the entire weekend! We want to see all your photos and videos.</p>
            </div>
            <div className="m-3">
                <h3>The Ceremony</h3>
                <p><span>&#8226;</span> We’ll review the processional order at rehearsal. Once you make it up the aisle, please take your seat in the reserved rows.</p>
                <p><span>&#8226;</span> After the ceremony concludes, we’ll move right into cocktail hour on the patio. Encourage guests to head over there and go get a drink!</p>
            </div>
            <div className="m-3">
                <h3>The Party</h3>
                <p><span>&#8226;</span> During the cocktail hour, please encourage guests to participate in the signing of our guest book and submitting their photos to our website. And of course, be sure to do these things yourself!</p>
                <p><span>&#8226;</span> When the time comes, we might need a hand laying out desserts and keeping the popcorn machine up and running!</p>
            </div>
        </div>
    </div>
}