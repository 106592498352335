export default function Itinerary() {
    return <div>
        <div className='d-flex justify-content-center align-items-center flex-column mb-3'>
            <div><h1 className="display-5">Order of Events</h1></div>
            <p><i>Subject to change slightly<br/>as we finalize the details.</i></p>
        </div>
        <div className='d-flex justify-content-center align-items-center flex-column mb-0'>
            <div className="mb-3">
                <p className="m-0">3:30</p>
                <p>Ceremony</p>
            </div>
            <div className="mb-3">
                <p className="m-0">4:00</p>
                <p>Cocktail Hour</p>
            </div>
            <div className="mb-3">
                <p className="m-0">5:00</p>
                <p>First Dance</p>
            </div>
            <div className="mb-3">
                <p className="m-0">5:30-7:00</p>
                <p>Food Truck service</p>
            </div>
            <div className="mb-3">
                <p className="m-0">9:55</p>
                <p className="m-0">Last Dance</p>
            </div>
        </div>
    </div>
}