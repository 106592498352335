import dress_1 from '../assets/dresses_1.jpg'
import dress_2 from '../assets/dresses_2.jpg'
import dress_3 from '../assets/dresses_3.jpg'
import dress_4 from '../assets/dresses_4.jpg'

export default function Girls() {
    return <div  className="m-3">
        <h1>Hi Girls!</h1>
        <p className="m-3">
            You have so much time to find a dress, but I wanted to get some inspo out there so you can keep it in the back of your mind. I think you’ll have more luck finding the right style after the holidays.</p>
        <p className="m-3">
            I just adore the mismatched look for the girls, and I really hope you’ll find a dress you love and want to wear again and again! In fact, if you already have something that fits this style in your closet, please wear it!
        </p>
        <p className="m-3">
            Despite our wedding being in the fall, I really love these spring color palates. Light greens, blues, blushes, yellows. And more so, I love the florals and variety of textures.         </p>
        <p className="m-3">
            So find a pretty dress! Preferably ankle or floor length. Solid or patterned depending on your liking. Something in this gentle, pastel-y color scheme. Any material. The more variety we have, the better!        </p>
        <div className="d-flex justify-content-center">
            <div className="m-2 image-container">
                <img src={dress_3} alt="us1"></img>
            </div>
            <div className="m-2 image-container">
                <img src={dress_2} alt="us2"></img>
            </div>
        </div>
        <div className="d-flex justify-content-center">
            <div className="m-2 image-container">
                <img src={dress_4} alt="us3"></img>
            </div>
            <div className="m-2 image-container">
                <img src={dress_1} alt="us3"></img>
            </div>
        </div>
        <p className="m-3">
            Feel free to shop wherever, but please keep small and local businesses in mind! There are lots of independent boutiques I’ve been finding online that will offer free shipping and sometimes they’ll even send goodies along too!         </p>
        <p className="m-3">
            And once you find the perfect dress, send it over to me and I’ll share it here so we can watch it all come together.
        </p>
    </div>
}