import { Link, useMatch, useResolvedPath } from "react-router-dom"
import "../nav-style.css";

export default function VIP() {
    // Functions
    function CustomLink({ to, children, ...props }) {
        const resolvedPath = useResolvedPath(to)
        const isActive = useMatch({path: resolvedPath.pathname, end:true})
        return (
            <li className={isActive ? "active mb-4" : "mb-4"}>
                <Link to={to}>{children}</Link>
            </li>
        )
    }

    // HTML
    return <div>
        <div className='d-flex justify-content-center align-items-center flex-column mb-3'>
            <div><h1 className="display-5">VIP Cheat Sheets</h1></div>
        </div>
        <div className='d-flex justify-content-center align-items-center flex-column mb-0'>
            <nav className="d-flex flex-row align-items-center w-100 nav">
                <ul className="w-100 d-flex justify-content-center h5 flex-column align-items-center">
                    <CustomLink to="/thursday">Weekend Timeline</CustomLink>
                    <CustomLink to="/responsibilities">Wedding Party<br/>Responsibilities </CustomLink>
                </ul>
            </nav>
        </div>
    </div>
}