import Timeline from '../Timeline';

export default function Friday() {
    return <div className='d-flex justify-content-center align-items-center flex-column mb-0 ml-2 mr-2 w-100'>
        <Timeline />
        <div className="m-3 mt-5">
            <h3 className="mb-4">Friday</h3>
            <p>1:00 - Check in begins</p>
            <p>6:00 - Rehearsal begins</p>
            <p className="mb-5">6:30 - Dinner served</p>
            <p className="mb-5">Speeches/Toasts to take place at Rehearsal Dinner</p>
            <p className="mb-5">Dress code is dressy casual. Have fun with it.</p>
            <p>Address: <br/> 143 North Galen Hall Road <br/>Wernersville, Pennsylvania 19565</p>
        </div>
    </div>
}