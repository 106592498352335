import Timeline from '../Timeline';

export default function Thursday() {
    return <div className='d-flex justify-content-center align-items-center flex-column mb-0 ml-2 mr-2 w-100'>
        <Timeline />
        <div className="m-3 mt-5">
            <h3 className="mb-4">Thursday</h3>
            <p className="mb-5">4:00 - Ceremony and Mass held at St Mary’s Catholic Church</p>
            <p>Address: <br/>14833 Kutztown Rd, <br/>Kutztown, PA 19530</p>
        </div>
    </div>
}