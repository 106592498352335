import Timeline from '../Timeline';

export default function Saturday() {
    return <div className='d-flex justify-content-center align-items-center flex-column mb-0 ml-2 mr-2 w-100'>
        <Timeline />
        <div className="m-3 mt-5">
            <h3 className="mb-4">Saturday</h3>
            <p>8:00 - breakfast</p>
            <p>9:00 - hair and makeup begins</p>
            <p>12:30 - photography coverage begins with AJ “waiting around”</p>
            <p>12:45 - Cassie and Martina getting ready photos</p>
            <p>1:00 - AJ head to first look location</p>
            <p>1:05 - Cassie and Jon first look</p>
            <p>1:15 - Cassie and AJ private first look and couples portraits</p>
            <p>2:00 - wedding party photos</p>
            <p>2:30 - immediate family photos</p>
            <p>3:00 - bride and groom head back inside the inn before guests arrive</p>
            <p>3:15 - line up for ceremony</p>
            <br />
            <br />
            <br />
            <p>3:30 - wedding ceremony</p>
            <p>4:00 - cocktail hour on the patio (including charcuterie, hors d’oeuvres, mini arepas)</p>
            <p>4:50 - bar service pauses</p>
            <p>4:55 - gather all guests around dance floor</p>
            <p>5:00 - first dance, followed by father daughter dance, mother son dance</p>
            <p>5:15 - Cassie and AJ thanks yous</p>
            <p>5:15 - photo dash with all guests</p>
            <br />
            <br />
            <br />
            <p>5:30 to 10:00 - dance floor open </p>
            <p>5:30 - food truck service begins</p>
            <p>6:00 - bride and groom sneak off for a few quick sunset pictures </p>
            <p>6:30 - photography coverage ends</p>
            <br />
            <br />
            <br />
            <p>7:00 - food truck leaves</p>
            <p>8:00 - desserts and late night snacks</p>
            <p>9:30 - last call for drinks</p>
            <p>10:00 - end of night</p>
        </div>
    </div>
}