import Timeline from '../Timeline';

export default function Sunday() {
    return <div className='d-flex justify-content-center align-items-center flex-column mb-0 ml-2 mr-2 w-100'>
        <Timeline />
        <div className="m-3 mt-5">
            <h3 className="mb-4">Sunday</h3>
            <p>For those staying at the inn, please join us Sunday morning for a farewell brunch.</p>
        </div>
    </div>
}