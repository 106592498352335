import { Link, useMatch, useResolvedPath } from "react-router-dom"
import "../nav-style.css";

export default function Timeline() {
return <div className='d-flex justify-content-center align-items-center flex-column mb-0 ml-2 mr-2 w-100'>
    <div className="m-3">
        <nav className="d-flex flex-row align-items-center w-100 nav cheat">
            <ul className="w-100 d-flex justify-content-center h6">
                <CustomLink to="/vip"> &lt; Back to Cheat Sheets</CustomLink>
            </ul>
        </nav>
    </div>
    <div className="w-100">
    <nav className="d-flex flex-row align-items-center w-100 nav">
        <ul className="w-100 d-flex justify-content-center h6">
            <CustomLink to="/thursday">Thursday</CustomLink>
            <CustomLink to="/friday">Friday</CustomLink>
            <CustomLink to="/saturday">Saturday</CustomLink>
            <CustomLink to="/sunday">Sunday</CustomLink>
        </ul>
    </nav>
    </div>
</div>
}

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({path: resolvedPath.pathname, end:true})
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to}>{children}</Link>
        </li>
    )
}