import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

export default function App() {
  const [song, setSong] = useState("");
  const [loading, setLoading] = useState("");
  const [showToast, setShowToast] = useState("");
  const [showCheckMark, setShowcheckMark] = useState("");
  const toggleShowToast = () => setShowToast(!showToast);

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  function Submit(e) {
    e.preventDefault();
    setLoading(true);
    setShowcheckMark(false);
    if (showToast) {
        toggleShowToast();
    }

    fetch(
      "https://script.google.com/macros/s/AKfycbxKhzntnU3Zdu4cHIMssN9eQcnUogOOhB1ja-7WJyitdLzxQvFvbGmJ9gXEVDLeM3Ah2g/exec",
      {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: '{"Song":"' + song + '"}',
        mode: "no-cors",
        cache: "no-cache"
      }
    )
    .then(async (res) => {
        console.log("Success");
        setShowToast(true);
        setShowcheckMark(true);
        setLoading(false);
        await timeout(2000);
        setShowcheckMark(false);
    })
    .catch((error) => {
        console.log('Error' + error);
    });
  }

  return (
    <div>
        <p>Thanks! We can't wait to celebrate with you.</p>
        <p>We hope to see you out on the dance floor! What song will get you out there?</p>
      <div>
        <form className="form flex-column justify-content-center" onSubmit={(e) => Submit(e)}>
            <div  className="mb-3 d-flex justify-content-center">
                <Form.Control disabled={(loading && !showCheckMark) || (!loading && showCheckMark)} className="w-50" onChange={(e) => setSong(e.target.value)}type="text" />
            </div>
            <div>
                <Button disabled={(loading && !showCheckMark) || (!loading && showCheckMark)} variant="primary" type="submit" bsPrefix="custom-btn">
                    {!loading && !showCheckMark && <div>Submit</div>}
                    {loading && !showCheckMark && <Spinner size="sm" animation="border" role="status"></Spinner>}
                    {!loading && showCheckMark && <div>&#x2713;</div>}
                </Button>{' '}
            </div>
        </form>
      </div>
    </div>
  );
}