import React, { Component } from 'react';

class CountdownWeeks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            weeks: 0
        }
        
    }

    componentDidMount() {
        this.setupCountdown();
    }

    setupCountdown() {
        this.interval = setInterval(() => {
            const date = this.calculateCountdown(this.props.date);
            date ? this.setState(date) : this.stop();
        }, 0);
    }

    stop() {
        clearInterval(this.interval);
    }

    calculateCountdown (endDate) {
        var timeLeft = {
            weeks: 0
        };
        
        let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;    
        timeLeft.weeks = Math.floor(diff / (86400*7));

        return timeLeft;
    }

    render() {
        const countDown = this.state;
    
        return (
          <div className="Countdown">
            <span className="m-3">
                <span className="m-2 weeks-font Header-Font">{countDown.weeks === 0 ? '??' : countDown.weeks} Weeks</span>
            </span>
          </div>
        );
      }
}

export default CountdownWeeks;