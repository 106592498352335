export default function OurStory() {
    return <div>
        <h1 className="display-5">Our Story</h1>
        <p className="m-3">
            It’s been a long time coming. We know.</p>
        <p className="m-3">
            We suppose it all started with a silly promposal, but somewhere along the way, there became a permanence to us. 
        </p>
        <p className="m-3">
            Thinking back to the earlier days, like our cross-country road trip in 2016, we still see ourselves as the same couple. Two people, completely enthralled with tackling every obstacle and pursuing every opportunity together. Whether we’re traveling someplace new, like the month we spent in Europe in 2019, or we’re just headed down the road to the local hardware store, like when we started converting our camper van in 2021, we continue to strengthen our bond as a team.
        </p>
        <p className="m-3">
            We can’t pinpoint the distinct moment we felt like family, but perhaps it became most obvious to everyone else when we brought home our wild child pup, Yara, in the start of 2020 (who, by the way, is just as enthralled with tackling obstacles and pursuing opportunities as us - maybe even more). It can be hard to remember our lives before her.
        </p>
        <p className="m-3">
            To slow time down and offset the mundane, we try to live most days with intention, challenging and encouraging each other as we develop these days into a life. Both through passions we practice individually and ones we share together, we emphasize and linger on the good. Most evenings you’ll find us unwinding under our maple tree, AJ playing guitar and Cassie throwing a frisbee for Yara. It’s in quiet, ordinary moments like these where we are incredibly grateful for each other’s dedication.
        </p>
        <p className="m-3">
            We talk often about the future, about our dreams, our fears. But we remember to prioritize awareness of the current moment and trust that our teamwork and resolve will guide us as we move through life.
        </p>
        <p className="m-3">
            As we set off to embark on this next chapter, we couldn’t be more excited to celebrate with those we love most. It’s our hope for an intimate, intentional day filled with both soft smiles and big laughs - and lots of dancing!
        </p>
    </div>
}