import '../App.css'
import React, { Component } from 'react'
import LadyOrGentleman from './LadyOrGentleman'
import alex from '../assets/Alex_2.JPEG'
import gray from '../assets/gray.jpeg'
import sam from '../assets/sam_2.jpg'
import kyra from '../assets/kyra.png'
import colleen from '../assets/col_2.jpg'
import zach from '../assets/zach.jpg'
import will from '../assets/will.JPG'
import meag from '../assets/meag.jpg'
import kate from '../assets/kate.jpg'
import tyler from '../assets/tyler.jpg'
import jj from '../assets/jj.jpg'

class LadiesAndGentlemen extends Component {
    ladiesAndGentlemen = [
        { 
            fname: "Alex",
            lname: "Foulke",
            picture: alex        },
        { 
            fname: "Colleen",
            lname: "Keevert",
            picture: colleen        },
        { 
            fname: "Grayson",
            lname: "Keevert",
            picture: gray        },
        { 
            fname: "Jameson",
            lname: "Wildonger",
            picture: jj        },
        { 
            fname: "Kate",
            lname: "Wildonger",
            picture: kate        },
        { 
            fname: "Kyra",
            lname: "Lagunilla",
            picture: kyra        },
        { 
            fname: "Meaghan",
            lname: "Enos",
            picture: meag        },
        { 
            fname: "Samantha",
            lname: "Foulke",
            picture: sam        },
        { 
            fname: "Tyler",
            lname: "Blood",
            picture: tyler        },
        { 
            fname: "Willam",
            lname: "Walsh",
            picture: will        },
        { 
            fname: "Zach",
            lname: "Peace",
            picture: zach        }
    ];
    render() {
        return (
            <div className="App">            
                <h1 className='Title-Font display-5'> Honorable Ladies and Gentlemen </h1>
                <p>There are already a lot of ways people refer to their bridal party. We decided to come up with another one. We’re calling these folks our honorable ladies and gentlemen, because each of them is so important to both of us.</p>
                {
                    this.ladiesAndGentlemen.map((person, i) =>
                    <LadyOrGentleman valueFromParent={this.ladiesAndGentlemen[i]}/>
                    )
                }
            </div>
        );
    }
}

export default LadiesAndGentlemen;