import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Song from './Song';

export default function App() {
  const [name, setName] = useState('');
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rsvp, setRsvp] = useState(false);
  const [rsvped, setRsvped] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showCheckMark, setShowcheckMark] = useState(false);
  const [showX, setShowX] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSong, setShowSong] = useState(false);
  const toggleShowToast = () => setShowToast(!showToast);

  function onOptionChange(e, index) {
    guests[index][2] = e.target.value;
  }

  useEffect(() => {
    if(guests != undefined && guests.length > 0) {
        setRsvp(true);
    }
  }, [guests]);

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  function checkForAnyYes() {
      var checkForYes = false;
      guests.forEach(guest => {
        console.log(guest)
        if (guest[2] === "y") {
            checkForYes = true;
        }
      });
      return checkForYes;
  }

  function convertToJSON(data) {
    // Initialize an empty array to store the converted data
    var jsonArray = [];
    
    // Loop through each object in the data array
    for (var i = 0; i < data.length; i++) {
      var obj = data[i];
      // Ensure the object has exactly 3 elements
      if (obj.length === 3) {
        // Push the object as an array to the jsonArray
        jsonArray.push(JSON.stringify(obj));
      }
    }
    
    // Convert the array of arrays to a JSON string
    var jsonString = JSON.stringify(jsonArray);
    
    // Return the JSON string
    return jsonString;
  }

  function SubmitPost(e) {
    e.preventDefault();

    setLoading(true);
    setShowcheckMark(false);
  
    fetch(
      "https://script.google.com/macros/s/AKfycbwai20bsQITYqExk2fRI-qB09hluesXodzUMa0jBaGob-3vU6ysUBqfInw8zPrSJ51ctw/exec",
      {
        method: "POST",
        headers: {
            'Content-Type': "text/plain;charset=utf-8"
        },
        body: convertToJSON(guests),
      }
    )
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setRsvped(true);
      setShowSong(checkForAnyYes());
      return response.json();
    })
    .then(data => {
      console.log('Success:', data);
      setRsvped(true);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error:', error);
      setLoading(false);
    });
  }

  function SubmitGet(e) {
    e.preventDefault();
    setLoading(true);
    setShowcheckMark(false);
    if (showToast) {
        toggleShowToast();
    }

    fetch(
      `https://script.google.com/macros/s/AKfycbwai20bsQITYqExk2fRI-qB09hluesXodzUMa0jBaGob-3vU6ysUBqfInw8zPrSJ51ctw/exec?name=${encodeURIComponent(name)}`)
    .then((res) => {
        setShowToast(true);
        return res.json();
    }).then(async (data) => {
        setGuests(data);
        if (data.length > 0) {
            setLoading(false);
            setShowError(false);
        } else {
            setShowX(true);
            setShowError(true);
            setLoading(false);
            await timeout(2000);
            setShowX(false);
        }
    })
    .catch((error) => {
        console.log('Error' + error);
    });
  }

  return (
    <div>
    <h1 className="display-5">RSVP</h1>
      <div>
        {!rsvp && <form className="form flex-column justify-content-center" onSubmit={(e) => SubmitGet(e)}>
            <p className="mb-5">Please enter your name as it was addressed on the invitation.</p>
            <div  className="mb-3 d-flex justify-content-center">
                <Form.Control disabled={(loading && !showCheckMark) || (!loading && showCheckMark)} className="w-50" onChange={(e) => setName(e.target.value)}type="text" />
            </div>
            <div>
                <Button disabled={(loading && !showCheckMark && !showX) || (!loading && (showCheckMark || showX))} variant="primary" type="submit" bsPrefix="custom-btn">
                    {!loading && !showCheckMark && !showX && <div>Submit</div>}
                    {loading && !showCheckMark && <Spinner size="sm" animation="border" role="status"></Spinner>}
                    {!loading && showCheckMark && <div>&#x2713;</div>}
                    {!loading && showX && <div>X</div>}
                </Button>{' '}
            </div>
        </form>
        }
        {showError &&
        <p className="m-1">We did not find someone for that name. Please make sure to enter your first and last name as it was addressed on the invitation.</p>}
        <div>
            {rsvp && !rsvped &&
            <form className="form flex-column justify-content-center" onSubmit={(e) => SubmitPost(e)}>
                <p className="mb-3">Please respond for your whole party.</p>
                {guests.map((guest, i) =>
                <div className="radio">
                    <p>{guest[0]}</p>
                    <div className="d-flex justify-content-center">
                        <div className="m-1 mb-4">
                            <label className="radioLabel">
                                <input type="radio" className="radioBtn" name={guest[0]} value="y" defaultChecked={guest[2]==='y'} onChange={(e) => onOptionChange(e, i)}/>
                                Accepts
                            </label>
                        </div>
                        <div className="m-1">
                            <label className="radioLabel">
                                <input type="radio"className="radioBtn" name={guest[0]} value="n" defaultChecked={guest[2]==='n'} onChange={(e) => onOptionChange(e, i)}/>
                                Declines
                            </label>
                        </div>
                    </div>
                </div>
                )}
                <Button disabled={(loading && !showCheckMark && !showX) || (!loading && (showCheckMark || showX))} variant="primary" type="submit" bsPrefix="custom-btn">
                    {!loading && !showCheckMark && !showX && <div>Submit</div>}
                    {loading && !showCheckMark && <Spinner size="sm" animation="border" role="status"></Spinner>}
                    {!loading && showCheckMark && <div>&#x2713;</div>}
                    {!loading && showX && <div>X</div>}
                </Button>{' '}
            </form>
            }
        </div>
        <div>
            {rsvped && showSong &&
                <Song />
            }
        </div>
        <div>
            {rsvped && !showSong &&
                <p>Thank you for responding.</p>
            }
        </div>
      </div>
    </div>
  );
}