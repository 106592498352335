export default function FAQ() {
    return <div>
        <h1 className="display-5">FAQ</h1>
        <br/><br/>
        <h3> Will the bride or groom be changing their last names?</h3>
        <p className="m-3">No. We will continue to be Cassie Peace and AJ Foulke.</p>
        &#9670;<h3 className="mt-3">Where are the ceremony and reception taking place?</h3>
        <p className="m-3">Spend the full day with us at the South Mountain Inn! Plan to be outside for the ceremony, cocktail hour, and reception. The dance floor will be under a tent, but we’ll have full access to the inn grounds. Feel free to bring a jacket to sit by the fire pit or step inside the inn to play some board games.</p>
        &#9670;<h3 className="mt-3">Is there parking at the venue?</h3>
        <p className="m-3">Yes. If you will be able to safely drive at the end of the night, feel free to park at the venue. Otherwise, we recommend carpooling with other guests or using ride sharing apps.</p>
        &#9670;<h3 className="mt-3">Do you have a hotel block?</h3>
        <p className="m-3">Yes, we have reserved rooms at the Hampton Inn in Wyomissing. Click <a href="https://www.hilton.com/en/book/reservation/deeplink/?ctyhocn=RDGWMHX&groupCode=CHH90I&arrivaldate=2024-10-18&departuredate=2024-10-20&cid=OM,WW,HILTONLINK,EN,DirectLink&fromId=HILTONLINKDIRECT">here</a> to book a room.</p>
        &#9670;<h3 className="mt-3">What is the dress code?</h3>
        <p className="m-3">Cocktail / Semi-Formal</p>
        &#9670;<h3 className="mt-3">Do I get a plus one?</h3>
        <p className="m-3">No. This is a smaller, more intimate wedding. All our guests will be specifically named on the invitations.</p>
        &#9670;<h3 className="mt-3">Are children invited?</h3>
        <p className="m-3">No. With the exception of our wedding party, this will be a child free event.</p>
    </div>
}